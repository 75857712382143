import Cookies from 'js-cookie';
import AuthService from '@/services/resources/AuthService';

export default {

    //fazer login do OAuth 2
    loginRequest(context, payload) {
        let service = AuthService.build();
        return new Promise( (resolve, reject) =>  {

            service.login(payload.username, payload.password)
                .then((resp) => {
                    let access_token = 'Bearer ' + resp.access_token;
                    Cookies.set('access_token', access_token, {
                        expires: 365
                    });

                    context.commit('authSuccess', access_token);
                    context.dispatch('userRequest');

                    
                    resolve(access_token);
                })
                .catch((err) => {
                    context.commit('authError', err);
                    Cookies.remove('access_token');
                    reject(err);
                })
        })

    },

    loginFromToken(context, payload){
        return new Promise( (resolve, reject) =>  {
            let access_token = 'Bearer ' + payload;
            Cookies.set('access_token', access_token, {
                expires: 365
            });

            console.log(access_token);

            context.commit('authSuccess', access_token);
            context.dispatch('userRequest');
            resolve(access_token);
        });
    },


    setLoading(context, payload){
        return new Promise( (resolve, reject) =>  {
            context.commit('setLoading', true);
        });
    },
    removeLoading(context, payload){
        return new Promise( (resolve, reject) =>  {
            context.commit('setLoading', false);
        });
    }
}