export default{
    getProfile: state => state.user,
    isProfileLoaded: state => !!state.user.name,
    isSeller: (state) => {

        let roles =  state.user.roles;

        if(!roles){
            return false;
        }

        var isSeller = false;
        roles.forEach(function(role, index){
            if(role.name === 'Seller'){
                isSeller = true;
               return;
           }
       });
        return isSeller;
    },
    isAdmin: (state) => {

        let roles =  state.user.roles;

        if(!roles){
            return false;
        }

        var Admin = false;
        roles.forEach(function(role, index){
            if(role.name === 'Admin'){
               Admin = true;
               return;
           }
       });
        return Admin;
    },
    isSDR: (state) => {

        let roles =  state.user.roles;

        if(!roles){
            return false;
        }

        var isSDR = false;
        roles.forEach(function(role, index){
            if(role.name === 'Sdr'){
                isSDR = true;
               return;
           }
       });
        return isSDR;
    }
}
