import middleware from '@/router/middleware'
import authentication from '@/router/middleware/authentication'
import unauthentication from '@/router/middleware/unauthentication'

export default [{
    path: '/register',
    name: 'Register',
    component: () => import('../../views/Register/Index.vue'),
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/register/success',
    name: 'Success',
    component: () => {
      return import('../../views/Success/Index.vue')
    },
    beforeEnter: middleware([unauthentication]),
  },
  {
    path: '/delete-account',
    name: 'DeleteAccount',
    component: () => {
      return import('../../views/Account/Delete.vue')
    },
    beforeEnter: middleware([unauthentication]),
  },
  {
    name: 'pageNotFound',
    path: '*',
    component: () => import('../../views/PageNotFound.vue'),
  }
]