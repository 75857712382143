<template>
  <div id="app">
    <!-- Admin -->
    <div v-if="isLogged">
  
      <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full" >
        <BaseNavbar />
        <b-row>
          <div :style="{'position': isMobile ? 'absolute' : 'relative'}" style="width: 290px" >
            <BaseSideNav />
          </div>
          <div :class="!isMobile ? 'desktop-content' : 'mobile-content'" id="app">
            <router-view></router-view>
          </div>
        </b-row>
      </div>
    </div>
  
    <div v-else id="main-wrapper">
      <router-view></router-view> 
    </div>
  
  </div>
  </template>
  
  <script>
  import store from '@/store/index'
  
  
  export default {
    name: "app",
    data(){
      return{
        client: {
          width: 0,
        },
      }
    },
    computed:{
      isLogged(){
        return store.getters.isAuthenticated;
      },
      isMobile() {
        // return this.client.width <= 768;
        return this.client.width <= 768;
      },
    },
    methods:{
       handleResize() {
        // padrão
        this.client.width = window.innerWidth;
      },
    },
    mounted(){
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
    },
    destroyed() {
      window.removeEventListener("resize", this.handleResize);
    }
  };
  </script>
  
  <style lang="scss">
  @import "./assets/scss/main.scss";
  @import "./assets/themes.css";
  
  * {
    font-family: "Montserrat", sans-serif;
  }
  
  body.preload {
    overflow: hidden;
  }
  ul {
    list-style: none;
  }
  a {
    text-decoration: none;
  }
  
  .desktop-content{
    width: calc(100% - 300px);
  }
  
  .mobile-content{
    width: 100%;
  }
  

  </style>
  