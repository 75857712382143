import UserLoggedService from '@/services/resources/UserLoggedService';

const service = UserLoggedService.build();

export default {
    userRequest: ({
        commit,
        dispatch
    }) => {
        return new Promise((resolve, reject) => {
            service.get('')
                .then((resp) => {
                    commit('userSuccess', resp);
                    commit('redirectToDashboard');
                    resolve(resp)
                })
                .catch((err) => {
                    commit('userError');
                    reject(err)
                    // if resp is unauthorized, logout, to
                    // dispatch('authLogout')
                });
        })

    },
}