import Vue from 'vue'
import App from './App.vue'
import BootstrapVue from 'bootstrap-vue';
import {
  library
} from '@fortawesome/fontawesome-svg-core';
import {
  fas
} from '@fortawesome/free-solid-svg-icons';
import {
  fab
} from '@fortawesome/free-brands-svg-icons';
import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome';
import VeeValidate from 'vee-validate';
import {
  Validator
} from 'vee-validate';
import pt_BR from 'vee-validate/dist/locale/pt_BR';
import router from './router';
import store from './store';
import components from './components/index'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueTheMask from 'vue-the-mask'
import validate from './validators/cpf_cnpj';
import cellphone from './validators/cellphone';
import './assets/scss/main.scss';

import PerfectScrollbar from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import './assets/scss/multiple-select.scss'

Vue.use(PerfectScrollbar)
Vue.use(VueTheMask)
library.add(fas);
library.add(fab);
Vue.use(VeeValidate)
Validator.localize('pt_BR', pt_BR);
Validator.extend('cpf_cnpj', validate);
Validator.extend('cellphone', cellphone);

Vue.directive("uppercase", {
  update: function (el) {
      el.value = el.value.toUpperCase()
  }
})

Vue.component('font-awesome-icon', FontAwesomeIcon);

for (let componentKey in components) {
  Vue.component(componentKey, components[componentKey])
}

Vue.use(BootstrapVue);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')